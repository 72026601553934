//////////////////////////////////////////////////

// Theme: Ionic 3 UI Theme/Template App - Material Design - Blue Light
// Web Site: https://csform.com/
// Author: CSFORM
// Email support:  dev@csform.com

//////////////////////////////////////////////////
/* 1# Global Settings Font Style Section
/* 2# Global Settings Font Size Section
/* 3# Global Colors Section
/* 4# Global Opacity Section
/* 5# Function to print color Section
/* 6# Gradient Settings Section
/* 7# Changing Background Images in Black&White Mode Section
/* 8# Darkening and Black&White Blend Mode Section
/* 9# Settings Header Animation Section
/* 10# Settings Spinner Section
/* 11# Settings Header Section
/* 12# Settings Divider Section
/* 13# Settings Shadows Section
/* 14# Settings Icon Section
/* 15# Settings Social Icons Color Section
/* 16# Settings Span Section
/* 17# Settings Header Section
/* 18# LIST VIEWS - Item Parameters Section
/* 19# LIST VIEWS - Subitem Parameters Section
/* 20# Settings Button Section
/* 21# Settings Fab Button Section
/* 22# Settings Google Cards Section
/* 23# Settings Swipe Section
/* 24# Settings Parallax Section
/* 25# Settings Checkbox Section
/* 26# Settings Toggle Section
/* 27# Settings Radio Section
/* 28# Settings Wizard Section
/* 29# Settings Input Field Section
/* 30# Settings Tabs Section
/* 31# Settings Search Bar Section
/* 32# Settings Range Section
/* 33# Settings Badge Section
/* 34# Settings Divider Section
/* 35# Settings Google Maps  Section
/* 36# Settings Toolbar Section
/* 37# Settings Alert with input Settings
/* 38# Default Overview Ionic Section
//////////////////////////////////////////////////


/* 1# Global Settings Font Style Section
================================================*/
@import "open-sans";
$font-family-md-base: 'Open Sans', sans-serif !default;
$font-family-ios-base: 'Open Sans', sans-serif !default;


/* 2# Global Settings Font Size Section
================================================*/
$h1-font-size: 24px;
$h2-font-size: 14px;
$h3-font-size: 16px;
$h4-font-size: 14px;
$h5-font-size: 12px;
$h6-font-size: 10px;
$text-font-size: 12px;


/* 3# Global Avatar Size Section
================================================*/
$item-md-avatar-size : 60px;
$item-ios-avatar-size : 60px;


/* 4# Global Thumbnail Size Section
================================================*/
$item-md-thumbnail-size: 120px;
$item-ios-thumbnail-size: 120px;


/* 5# Global Item Size Section
================================================*/
// Settings For Android
$item-md-padding-top: 25px;
$item-md-padding-bottom: 25px;
// Settings For Ios
$item-ios-padding-top: 30px;
$item-ios-padding-bottom: 30px;

/* 6# Global Border Radius Section
================================================*/
$border-radius: 4px;


/* 7# Global Colors Section
================================================*/
$palettes: (
  mainColors: ( // Also used for backgrounds.
      primary:   #1d78ae, // Used for toolbars, button-clear-outline, checkbox, toggle-background, tabs-background, map-header, alert
      secondary:  #f7f7f7, // Used for various small elements, item-divider, swipe section, background Ionic App, item-md-sliding-content-background

      //secondary-first:  #1d78ae, //Used for gradient
      secondary-first:  #1d78ae, // Used for gradient 
      accent:   #ffffff, //Used for icon-search-bar, loaders-spinners, checkbox-item, icon, button, span, item-divider, input-field-icons, black-white-mode, header section, google-card-background-color, checkbox, toggle, search bar, input-field
      accent-first: #1d78ae, // Used for spinner, button-ion-fab, badge
      //accent-first: #1d78ae, // Used for spinner, button-ion-fab, badge
      accent-other: #1d78ae, // Used for gradient, header animation, checkbox, radio, range
      //accent-other: #1d78ae, // Used for gradient, header animation, checkbox, radio, range
  ),

  textColors: ( // Used for text colors in all screens
      primary: #1d78ae, // Used for text colors in: header-title, icon, span, alert
      secondary: #1d78ae, // Used for text colors in: buttons, header-subtitle, input field, search-bar, alert
      accent:   #455B65, // Used for text colors in: item-title, item-subtitle, card-title, card-subtitle, card-body-text, swiped-item-title, parallax-description, map-header, map-content-title, maps-description
      accent-first: #ffffff, //Used for icon, subitem-title, subitem-subtitle, button, fab button, parallax-title, parallax-subtitle, checkbox, wizard-title, wizard-body-text, tabs, maps-title, maps-subtitle, toolbar
  ),
  blendModes: ( // Used for blend modes
      primary:  #ffffff,
      secondary: #1d78ae
  )
);


/* 8# Global Opacity Section
================================================*/
$global-opacity: (
  'opacity0': 0,
  'opacity10': 0.1,
  'opacity20': 0.2,
  'opacity30': 0.3,
  'opacity40': 0.4,
  'opacity50': 0.5,
  'opacity60': 0.6,
  'opacity70': 0.7,
  'opacity80': 0.8,
  'opacity90': 0.9,
  'opacity100': 1
);


/*  9# Function to print color Section
================================================*/
@function theme-colors($name: 'blendModes', $variant: 'primary', $opacity: 1) {
  // Get the color spectrum
  $color-spectrum: map-get($palettes, $name);
  // Get the color variant
  $color: map-get($color-spectrum, $variant);
  // Get the alpha setting
  $alpha: if(type-of($opacity) == 'number', $opacity, map-get($global-opacity, $opacity));
  // Set the alpha of the color
  @if $alpha {
    $color: rgba($color, $alpha);
  }
  @return $color;
}


/* 10# Gradient Settings Section
================================================*/
$color-1: theme-colors('mainColors', 'accent-other');
$color-2: theme-colors('mainColors', 'secondary-first');
@mixin gradient-accent ($color-1, $color-2) {
  background: $color-1;
  background: -moz-linear-gradient(left, $color-1 0%,  $color-2 100%);
  background: -webkit-linear-gradient(left, $color-1 0%, $color-2 100%);
  background: linear-gradient(to left, $color-1 0%, $color-2 100%);
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=  '$color-1', endColorstr='$color-1',GradientType=1 );
}


/* 11# Changing Background Images in Black&White Mode Section
==============================================================*/
@mixin black-white-mode {
  background-color: theme-colors('mainColors', 'accent');
  background-blend-mode: luminosity;
}


/* 12# Darkening and Black&White Blend Mode Section
=======================================================*/
@mixin darkening-blend-mode {
    @include black-white-mode;
     &::before {
       content: "";
       display: block;
       height: 100%;
       width: 100%;
       top: 0;
       left: 0;
       position: absolute;
       pointer-events: none;
       mix-blend-mode: multiply;
       @include gradient-accent ($color-1, $color-2);
     }
}

// Black&White Avatar and Image
/* ion-avatar, ion-thumbnail {
  -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
} */

[images-filter] {
  -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

img {
  object-fit: cover;
}

/* 13# Settings Header Animation Section
=======================================================*/
@mixin settingAnimationHeader {
  // Header off
  @keyframes headerOff {
    from {
      background-color: theme-colors('mainColors', 'accent-other');
    }

    to {
      background-color: transparent;
    }
  }

  ion-navbar {
    background-color: transparent;
    animation-name: headerOff;
    animation-duration: 400ms;
    background-image: none !important;
  }

  // Header On
  @keyframes headerOn {
    from {
      background-color: transparent;
    }

    to {

      /* background-color: #1d78ae;
      background-color: -webkit-gradient(linear, right top, left top, from(#1d78ae), to(#1d78ae));
      background-color: linear-gradient(to left, #1d78ae 0%, #1d78ae 100%); */

      background-color: #1d78ae;
      background-color: -webkit-gradient(linear, right top, left top, from(#1d78ae), to(#1d78ae));
      background-color: linear-gradient(to left, #1d78ae 0%, #1d78ae 100%);
    }
  }

  .active {
    animation-name: headerOn;
    animation-name: headerOn;
    animation-fill-mode: forwards;
    animation-duration: 400ms;
    box-shadow: $box-shadow-z1;
  }
}


/* 14# Settings Spinner Section
=======================================================*/
spinner {

  img {
    width: 70px !important;
    height: 70px !important;
    margin: 0 auto;
  }

  .swiper-pagination-bullet {
    background: theme-colors('mainColors', 'accent-first') !important;
  }
}


/* 15# Settings Header Section
================================================*/
// Settings Search For Android
$list-background-color: theme-colors('mainColors', 'accent');
$list-md-margin-bottom: 0;

// Settings Search For Ios
$list-ios-header-background-color: theme-colors('mainColors', 'accent');
$list-ios-header-border-bottom: none;

$list-md-activated-background-color: theme-colors('mainColors', 'accent');
$list-ios-activated-background-color: theme-colors('mainColors', 'accent');

$item-body-background-color: theme-colors('mainColors', 'accent') !default;


/* 16# Settings Divider Section
=======================================================*/
$item-divider-small: 1px solid theme-colors('mainColors', 'secondary');
$item-divider-medium: 2px solid theme-colors('mainColors', 'secondary');
$item-divider-large: 4px solid theme-colors('mainColors', 'secondary');


/* 17# Settings Shadows Section
=======================================================*/
$box-shadow-z1: 0 2px 10px rgba(0, 0, 0, 0.2);
$box-shadow-z2: 0 4px 10px rgba(0, 0, 0, 0.2);
$box-shadow-z3: 0 6px 10px rgba(0, 0, 0, 0.2);


/* 18# Settings Icon Section
================================================*/
// Icon Sizes
$icon-size:(
  'icon-small': 20px,
  'icon-medium': 32px,
  'icon-large': 60px
);
@function icon-size($key: 'icon-small') {
  @return map-get($icon-size, $key);
}

// Settings Icon Outline
i {
  //color: theme-colors('textColors', 'primary');
  color: theme-colors('textColors', 'accent-first');
  
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: theme-colors('textColors', 'accent-first');
}

[white] i{
  //color: theme-colors('textColors', 'primary') !important;
  color: theme-colors('textColors', 'accent-first') !important;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: theme-colors('textColors', 'accent-first') !important;
}

// Icon Settings Color and Size
[icon-small] {
  //color: theme-colors('textColors', 'primary') !important;
  color: theme-colors('textColors', 'accent-first') !important;
  font-size: icon-size('icon-small') !important;
}
[icon-medium] {
  //color: theme-colors('textColors', 'primary') !important;
  color: theme-colors('textColors', 'accent-first') !important;
  font-size: icon-size('icon-medium') !important;
}

[icon-large] {
  //color: theme-colors('textColors', 'primary') !important;
  color: theme-colors('textColors', 'accent-first') !important;
  font-size: icon-size('icon-large') !important;
}

[font-bold] {
  font-weight: bold !important;
}

[small-font] {
  font-size: $h2-font-size;
  margin-bottom: 5px;
}

[box-shadow] {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1) !important;
  z-index: 999;
}

.box-shadow {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1) !important;
  z-index: 999;
}

/* 19# Settings Social Icons Color Section
================================================*/
@mixin social {
   @if &{
    i.icon {
      color: theme-colors('textColors', 'primary');
      font-size: icon-size('icon-small');
      width: 20px;
    }
  }
}


/* 20# Settings Span Section
================================================*/
[span-small]{
  color: theme-colors('textColors', 'primary');
  font-size: $h5-font-size;
}

[span-medium]{
  color: theme-colors('textColors', 'primary');
  font-size: $h1-font-size;
}

[span-large]{
  color: theme-colors('textColors', 'primary');
  font-size: 30px !important;
}


/* 21# Settings Header Section
================================================*/
[header-title]{
   color: theme-colors('textColors', 'primary') !important;
   font-size: $h1-font-size;
   letter-spacing: normal;
   text-overflow: ellipsis;
   overflow: hidden;
   white-space:nowrap;
   display: block;
 }

 [header-subtitle]{
   color: theme-colors('textColors', 'secondary') !important;
   font-size: $h4-font-size;
   letter-spacing: 1px;
   font-weight: 300;
   text-overflow: ellipsis;
   overflow: hidden;
   white-space:nowrap;
   display: block;
 }


 /* 22# LIST VIEWS - Item Parameters Section
 ================================================*/
[item-title] {
  color: theme-colors('textColors', 'accent') !important;
  font-size: $h2-font-size !important;
  letter-spacing: 1px;
  text-overflow: ellipsis;
  overflow: hidden;
}

[item-subtitle] {
  color: theme-colors('textColors', 'accent') !important;
  font-size: $h5-font-size !important;
  text-transform: uppercase;
  font-weight: 300 !important;
  letter-spacing: 1px;
  text-overflow: ellipsis;
  overflow: hidden;
}


/* 23# LIST VIEWS - Subitem Parameters Section
================================================*/
[subitem-title] {
  color: theme-colors('textColors', 'accent-first');
  font-size: $h2-font-size !important;
  line-height: normal;
  letter-spacing: 1px;
  text-overflow: ellipsis;
  overflow: hidden;
}

[subitem-subtitle] {
  color: theme-colors('textColors', 'accent-first');
  font-size: $h5-font-size !important;
  font-weight: 300 !important;
  letter-spacing: 1px;
  text-overflow: ellipsis;
  overflow: hidden;
}


/* 24# Settings Button Section
================================================*/
[default-button] {
  @include gradient-accent ($color-1 !important, $color-2 !important);
  color: theme-colors('textColors', 'accent-first') !important;
  font-size: $h5-font-size !important;
  font-weight: 500 !important;
}

[button-clear] {
  color: theme-colors('textColors', 'secondary') !important;
  font-size: $h5-font-size !important;
}

[button-clear-outline] {
  color: theme-colors('textColors', 'secondary') !important;
  font-size: $h5-font-size !important;
  border: 1px solid theme-colors('mainColors', 'primary') !important;
  padding: 15px 25px !important;
  font-weight: bold !important;
}

[button-icon] {
    background: theme-colors('mainColors', 'primary') !important;
    color: theme-colors('textColors', 'accent-first') !important;
    font-size: $h5-font-size !important;
    font-weight: 700 !important;
    height: auto !important;
    box-shadow: none !important;
    border-radius: $border-radius !important;
    text-transform: capitalize !important;
    padding: 6px 10px 6px 8px !important;
}

[button-icon-clear] {
    background-color: transparent !important;
    color: #B2B2B2 !important;
    //padding: 5px !important;
    font-size: $h2-font-size !important;
    font-weight: 400 !important;
}

/* 25# Settings Fab Button Section
================================================*/
[button-ion-fab] {
  background-color: theme-colors('mainColors', 'accent-first') !important;
  color: theme-colors('textColors', 'accent-first') !important;
  bottom: 20px;
  right: 20px;
  -webkit-transition: all 300ms !important;
  transition: all 300ms !important;

  ion-icon {
    margin-top: 2px;
    display: block;
  }
}

// Fab Button Settings Animation
.fab-button-out {
  bottom: -80px;
  animation: fade-out 300ms ease-in;
  -ms-animation: fade-out 300ms ease-in;
  -webkit-animation: fade-out 300ms ease-in;
  -o-animation: fade-out 300ms ease-in;
  -moz-animation: fade-out 300ms ease-in;
}

@keyframes fade-out {
  from {
    bottom: 20px;
  }

  to {
    bottom: -80px;
  }
}


/* 26# Settings Google Cards Section
================================================*/
$card-md-margin-bottom: 0px !default;
$card-ios-margin-bottom: 0px !default;

$card-md-background-color: theme-colors('mainColors', 'accent');
$card-ios-background-color: theme-colors('mainColors', 'accent');

[card-title]{
  color: theme-colors('textColors', 'accent') !important;
  font-size: $h2-font-size !important;
  font-weight: normal !important;
  letter-spacing: 1px;
}

[card-subtitle]{
  color: theme-colors('textColors', 'accent') !important;
  font-size: $h5-font-size !important;
  font-weight: 300 !important;
  letter-spacing: 1px;
}

[card-body-text]{
  color: theme-colors('textColors', 'accent') !important;
  font-size: $text-font-size !important;
  font-weight: 300 !important;
  letter-spacing: 1px;
}


/* 27# Settings Swipe Section
================================================*/
$item-md-sliding-content-background: theme-colors('mainColors', 'secondary');

[swiped-item-title]{
  color: theme-colors('textColors', 'accent');
  margin: 0;
  font-size: $h5-font-size !important;
  text-transform: initial;
}


/* 28# Settings Parallax Section
================================================*/
[parallax-title]{
  color: theme-colors('textColors', 'accent-first');
  font-size: $h1-font-size !important;
  letter-spacing: 1px;
}

[parallax-subtitle]{
  color: theme-colors('textColors', 'accent-first');
  font-size: $h4-font-size !important;
  letter-spacing: 1px;
}
[parallax-description] {
  color: theme-colors('textColors', 'accent');
}


/* 29# Settings Checkbox Section
================================================*/
// Settings for android
$checkbox-md-icon-background-color-on: theme-colors('mainColors', 'accent-other');
$checkbox-md-icon-background-color-off: theme-colors('mainColors', 'accent');
$checkbox-md-icon-border-color-off: theme-colors('mainColors', 'primary');
$checkbox-md-icon-border-color-on: theme-colors('mainColors', 'primary');
$checkbox-md-icon-border-width: 2px;
$checkbox-md-icon-border-radius: 4px;
$checkbox-md-icon-size: 18px;
$checkbox-md-icon-checkmark-color:theme-colors('textColors', 'accent-first');
// Settings for ios
$checkbox-ios-background-color-on: theme-colors('mainColors', 'primary');
$checkbox-ios-background-color-off: theme-colors('mainColors', 'accent');
$checkbox-ios-icon-border-color-off: theme-colors('mainColors', 'primary');
$checkbox-ios-icon-border-color-on: theme-colors('mainColors', 'primary');


/* 30# Settings Toggle Section
================================================*/
// Settings for android
$toggle-md-handle-background-color-on: theme-colors('mainColors', 'primary');
$toggle-md-handle-background-color-off: #dedede;
$toggle-md-active-color: theme-colors('mainColors', 'accent');
$toggle-md-track-background-color-on: #dedede;
// Settings for ios
$toggle-ios-handle-background-color: theme-colors('mainColors', 'primary');
$toggle-ios-background-color-off: #dedede;;
$toggle-ios-background-color-on: #dedede;



/* Settings Seggments Section
================================================
================================================*/
$segment-button-md-border-bottom-color: theme-colors('mainColors', 'secondary') !important;
$segment-button-md-border-color-activated: theme-colors('mainColors', 'accent') !important;
$segment-button-md-text-color-activated: theme-colors('textColors', 'accent-first') !important;
$segment-button-md-height: 56px;
$segment-button-md-padding-top: 7px;
$segment-button-md-font-size: $h2-font-size;

/* 31# Settings Radio Section
================================================*/
// Settings for android
$radio-md-color-on: theme-colors('mainColors', 'accent-other');
// Settings for ios
$radio-ios-color-on: theme-colors('mainColors', 'accent-other');

/* 32# Settings Wizard Section
================================================*/
[wizard-title] {
  color: theme-colors('textColors', 'accent-first');
  font-size: $h3-font-size !important;
  letter-spacing: 1px;
  font-weight: 300;
}
[wizard-body-text]{
  color: theme-colors('textColors', 'accent-first', 'opacity60');
  font-size: $h4-font-size !important;
  letter-spacing: 1px;
  font-weight: 300;
}


/* 33# Settings Input Field Section
================================================*/
// Input Field Settings For Android
$text-input-md-highlight-color: theme-colors('textColors', 'secondary') !important;
$text-input-md-highlight-color-valid: theme-colors('textColors', 'secondary') !important;
$text-input-md-background-color: theme-colors('mainColors', 'accent') !important;
// Input Settings Label
$label-md-text-color: theme-colors('textColors', 'secondary') !important;
$label-ios-text-color: theme-colors('textColors', 'secondary') !important;
$text-input-ios-highlight-color: theme-colors('textColors', 'secondary') !important;
$text-input-ios-highlight-color-valid: theme-colors('textColors', 'secondary') !important;


/* 34# Settings Tabs Section
================================================*/
$tabs-background: theme-colors('mainColors', 'primary');
// Settings Search For Android
$tabs-md-tab-icon-color: theme-colors('textColors', 'accent-first', 'opacity50');
$tabs-md-tab-text-color: theme-colors('textColors', 'accent-first', 'opacity50');
$tabs-md-tab-text-color-active: theme-colors('textColors', 'accent-first');
$tabs-md-tab-icon-color-active: theme-colors('textColors', 'accent-first');
// Settings Search For Ios
$tabs-ios-tab-icon-color: theme-colors('textColors', 'accent-first', 'opacity50');
$tabs-ios-tab-text-color: theme-colors('textColors', 'accent-first', 'opacity50');
$tabs-ios-tab-text-color-active: theme-colors('textColors', 'accent-first');
$tabs-ios-tab-icon-color-active: theme-colors('textColors', 'accent-first');

[tabs-title] {
  color: theme-colors('textColors', 'accent-first');
}
[tabs-subtitle] {
  color: theme-colors('textColors', 'accent-first');
}


/* 35# Settings Search Bar Section
================================================*/
// Settings Search For Android
$searchbar-md-input-background-color: theme-colors('mainColors', 'accent') !important;
$searchbar-md-input-placeholder-color: theme-colors('textColors', 'secondary');
$searchbar-md-input-search-icon-color: theme-colors('textColors', 'secondary');
$searchbar-md-input-clear-icon-color: theme-colors('textColors', 'secondary');
$searchbar-md-background-color: transparent;
// Settings Search For Ios
$searchbar-ios-input-background-color: theme-colors('mainColors', 'accent') !important;
$searchbar-ios-input-placeholder-color: theme-colors('textColors', 'secondary');
$searchbar-ios-input-search-icon-color: theme-colors('textColors', 'secondary');
$searchbar-ios-input-clear-icon-color: theme-colors('textColors', 'secondary');
$searchbar-ios-background-color: transparent;
$searchbar-ios-border-color: transparent;


/* 36# Settings Range Section
================================================*/
// Settings Search For Android
$range-md-bar-active-background-color: theme-colors('mainColors', 'accent-other');
// Settings Search For Ios
$range-ios-bar-active-background-color: theme-colors('mainColors', 'accent-other');
$range-ios-knob-background-color: theme-colors('mainColors', 'accent-other');


/* 37# Settings Badge Section
================================================*/
$badge-font-size: $h3-font-size;
// Settings Search For Android
$badge-md-background-color: red;
$badge-md-text-color: #ffffff;
// Settings Search For Ios
$badge-ios-background-color: red;
$badge-ios-text-color: #ffffff;


/* 38# Settings Divider Section
================================================*/
$item-ios-divider-background: theme-colors('mainColors', 'secondary');
// $item-md-divider-background: theme-colors('mainColors', 'secondary');


/* 39# Settings Google Maps Section
================================================*/
//  Google Maps
[map-header]{
  background-color: theme-colors('mainColors', 'primary');
  ion-icon {
    color: theme-colors('textColors', 'accent');
  }
}
[maps-title] {
    font-size: $h1-font-size;
    color: theme-colors('textColors', 'accent-first');
}
[maps-subtitle] {
  font-size: 14px;
  font-size: $text-font-size;
  color: theme-colors('textColors', 'accent-first') !important;
}

[map-content] {
  [title] {
    font-size: 14px;
    color: theme-colors('textColors', 'accent');
  }

  [maps-description] {
  color: theme-colors('textColors', 'accent');
    font-size: $text-font-size;
  }

  [info] {

    ion-icon {
      margin: 7px 0 0 !important;
    }

    .label-md {
      margin: 0 !important;
    }

    .item-block {
      min-height: 1.5em !important;
    }
  }
}


/* 40# Settings Toolbar Section
================================================*/
// For Android
$toolbar-md-title-text-color: theme-colors('textColors', 'accent-first');
$toolbar-md-title-font-size: 15px;
$toolbar-md-button-color: theme-colors('textColors', 'accent-first');
// For Ios
$toolbar-ios-title-text-color: theme-colors('textColors', 'accent-first');
$toolbar-ios-button-color: theme-colors('textColors', 'accent-first');
$toolbar-ios-title-font-size: 15px;

.toolbar {
  /* box-shadow: 0px 0px 3px 3px #888 !important; */
  .toolbar-background {
    @include gradient-accent ($color-1, $color-2);
  }

  .toolbar-title {
    font-weight: bold;
    text-transform: uppercase;
  }
}


/* 41# Settings Alert with input Settings
================================================*/
  // Settings for Android
$alert-md-input-border-color: theme-colors('mainColors', 'primary');
$alert-md-input-text-color: theme-colors('mainColors', 'primary') !important;
$alert-md-input-border-color: theme-colors('mainColors', 'primary');
$alert-md-input-border-color-focused: theme-colors('mainColors', 'primary');
$alert-md-title-font-size: 16px;
$alert-md-input-border-width-focused: 1px;
$alert-md-message-text-color: theme-colors('mainColors', 'primary');
  // Settings for Ios
$alert-ios-message-text-color: theme-colors('mainColors', 'primary') !important;
$alert-ios-input-background-color: transparent;
$alert-ios-button-background-color-activated: theme-colors('mainColors', 'primary', 'opacity40') !important;
$alert-ios-input-border: 1px solid theme-colors('mainColors', 'primary');
$alert-ios-sub-title-text-color: red !important;
.alert-title {
  color: theme-colors('textColors', 'primary') !important;
  font-weight: 400 !important;

  float:left;  //user_added
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: theme-colors('textColors', 'secondary') !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: theme-colors('textColors', 'secondary') !important;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: theme-colors('textColors', 'secondary') !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: theme-colors('textColors', 'secondary') !important;
}


/* 42# Default Overview Ionic Section
================================================*/

[no-margin] {
  margin: 0 !important;
}


//* Background Settings Section */
[background-size] {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

//Background Ionic App
$background-color: theme-colors('mainColors', 'secondary');
// For IOS item-divider
$item-ios-divider-background: theme-colors('mainColors', 'accent');

[white-background] {
  background-color: theme-colors('mainColors', 'accent') !important;
}

[transparent] {
  background-color: transparent !important;
}

//Default Overview Ionic
  [has-header] .scroll-content{
    top: 56px;
  }

  //Settings header for IOS
  .platform-ios {
    ion-list {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    [has-header] .scroll-content {
      top: 64px;
    }
  }


  /* Settings Alert Info
  ================================================
  ================================================*/
  .info-dialog {
    .alert-head,
    .alert-message {
      text-align: center !important;
    }

    .alert-message {
      color: theme-colors('textColors', 'accent') !important;
    }

    .alert-sub-title {
      color: theme-colors('textColors', 'primary');
    }
  }

  /* Settings Alert Warning
  ================================================
  ================================================*/
  .alert-warning {
    .alert-head,
    .alert-message {
      text-align: center !important;
    }

    .alert-message {
      color: theme-colors('textColors', 'accent') !important;
    }

    .alert-sub-title {
      color: theme-colors('textColors', 'primary');
    }

    // .alert-button-group {
    //   padding: 8px 0 8px 8px !important;
    // }


    .alert-button{
      width: 100%;
      margin-bottom: 20px;
      background: theme-colors('mainColors', 'primary') !important;
      text-align: center;
      color: theme-colors('textColors', 'accent-first') !important;
      display: block;

      .button-inner {
        -webkit-box-pack: end;
        -webkit-justify-content: none !important;
        -ms-flex-pack: end;
        justify-content: center !important;
        }
      }
  }

  /* Settings Alert Subscribe
  ================================================
  ================================================*/
  .alert-subscribe {
    .alert-head,

    .alert-message {
      text-align: center !important;
    }

    .alert-message {
      color: theme-colors('textColors', 'primary') !important;
    }

    .alert-sub-title {
      color: theme-colors('textColors', 'accent');
    }
  }

  .background_color{
    background-color:#fcfdff !important;
  }

  .buttonCss {

    text-align: center !important;
    button.alert-button:nth-child(1){
      color: #ffffff !important;
      background-color:  #455B65 !important;
      /* min-width: 40%; */
    }
  
    button.alert-button:nth-child(2){
      color: #ffffff !important;
      background-color: theme-colors('textColors', 'primary') !important;
      /* min-width: 40%; */
      -webkit-justify-content: none !important;
      justify-content: none !important;
    }

  }

 