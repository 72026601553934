/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";




.alertstar{
      
    .rating-alert {
      width: 30px !important;
      margin: 0 !important;
      background-image:url('assets/icon/star-outer.png') !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      color:rgba(0, 0, 0, 0) !important;
    }
    
    .alert-title {
      font-size: 21px;
      color: #1d78ae !important;
      font-weight: 700 !important;
      text-align: center;
      float: none;
    }
    .alert-sub-title {
      font-size: 14px;
      text-align: center;
      margin: 0px;
    }
    .alert-button-group{
      padding:0;
      padding-bottom: 10px; 
      flex-direction: row; 
      justify-content: space-around;
    }
    border-radius: 12px;
  
    .alert-wrapper{
      border-radius: 12px;
      width: 100% !important;
      max-width: 450px !important;
      height: 25%;
    }
  }


.buttonCss{
  width: 100% !important;
  .alert-wrapper{
    width: 96% !important;
    max-width: 100% !important;
  }
}

.alert-warning{
  width: 100% !important;
  .alert-wrapper{
    width: 96% !important;
    max-width: 100% !important;
  }
}

hr{
  height: 0px !important;
  border: 0.1px solid #80808036 !important;
  box-sizing: unset;
}

ion-title{
  font-size: 100%;
  font-weight: bolder;
}


.item-native{
  padding: 0% !important;
}

ion-item .item-native{
  padding-left: 0% !important;
}

.alert-warning .alert-button-group {
  padding: 8px 120px 8px 120px !important;
}

.alert-button-inner.sc-ion-alert-md {
  justify-content: center;
}

ion-button{
  font-size: 0.8rem;
}


//alert width increased 11-11-2020
.alert-wrapper{
  border-radius: 12px !important;
  width: 100% !important;
  max-width: 450px !important;
}

.alert-md .alert-message {
  color: #000000;
}

.alert-warning .alert-button-group {
  padding: 8px 120px 8px 120px !important;
}


.profile-warning .alert-button-group{
  padding : 0 !important;
}

.alert-radio-label.sc-ion-alert-md {
  font-size: 14px !important;
}

.alert-title.sc-ion-alert-md{
  font-size: 18px !important;
}

.error{
  color: red;
  font-size: 11px !important;
  display: flex !important;
  padding-left: 5% !important;
  padding-top: 2% !important;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}